.mandir-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.mandir-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    max-width: 250px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;

}

.readmore_toggle_text{
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: .7em;
    letter-spacing: .1em;
    outline: 0;
    color: dodgerblue;
    cursor: pointer;
}

.mandir-card .mandir-content{
    min-height: 170px;
}

.related-item{
    min-height: 40px; 
}
.mandir-images img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 0px;
}

.mandir-card h3 {
    margin-top: 10px;
    font-size: 22px;
    line-height: 32.69px;
    font-weight: 900;
}

.mandir-card ul {
    list-style-type: disc;
    padding-left: 20px;
}

.mandir-card .perticipate_link{
    width: 97% !important;
    border-radius: 12px !important;
    background-color: rgb(20, 148, 110);
    color: #ffffff  !important;
}

.mandir-card .mandir-icon{
    display: block;
    height: 50px;
    width: 26px;
    float: left;
}
.mandir-card .mandir-icon img{
     width: 22px;
    height: 22px;
    float: left;
    padding: 0px;
    margin: 0px 6px 0px 0px;
}

.mandir-card .mandir-discription{
    font-size: 14px;
}

.mandir-card .card-title{
    font-size: 12px;
}

.mandir-card .related-item h3{
    font-size: 14px;
    line-height: 17px;
}



.slick-slide{
    transition: opacity 3s ease-in-out;
}
