.puja-store-list {
    padding: 20px;
}

.product-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
}

.product-table-header, .product-table-row {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.product-table-header {
    background-color: #f2f2f2;
}

.product-table-header > div, .product-table-row > div {
    flex: 1;
    text-align: left;
    padding: 8px;
}

.product-table img {
    max-width: 100px;
    height: auto;
}

.btn {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}

.btn-edit {
    background-color: #4CAF50;
}

.btn-delete {
    background-color: #f44336;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    cursor: pointer;
}

.pagination span {
    margin: 0 10px;
}
